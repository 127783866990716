<template>
  <div>
    <el-row class="site-navigation navigation-bar">
      <el-col
        :xl="4"
        :lg="3"
        :md="4"
        :sm="6"
      >
        <router-link
          :to="{ name: 'public-home' }"
          class="navbar-brand"
        >
          <vsvg-icon
            data="@assetsIcon/brand/logo-icon.svg"
            scale="1"
            original
          />
        </router-link>
      </el-col>
      <el-col
        :xl="13"
        :lg="14"
        :md="13"
        class="hidden-sm-and-down"
      >
        <el-menu
          ref="menu"
          :default-active="activeMenuItem"
          class="navbar"
          mode="horizontal"
          menu-trigger="click"
        >
          <el-menu-item index="public-home">
            <router-link :to="{ name: 'public-home' }">
              Home
            </router-link>
          </el-menu-item>
          <el-menu-item index="public-why">
            <router-link :to="{ name: 'public-why' }">
              Why should I join?
            </router-link>
          </el-menu-item>
          <el-menu-item
            v-if="$store.state.isLogged && isActiveBrand(['getactivehub'])"
            index="public-faq"
          >
            <router-link :to="{ name: 'public-faq' }">
              FAQs
            </router-link>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col
        class="btns-nav hidden-sm-and-down"
        :xl="7"
        :lg="7"
        :md="7"
      >
        <button
          v-if="!$store.state.isLogged"
          class="fgh-button mini fgh-button--primary btn-register"
          @click="$events.emit('showRegisterDialog')"
        >
          Register
        </button>
        <router-link
          v-if="!$store.state.isLogged"
          v-slot="{ navigate }"
          :to="{ name: 'auth-login' }"
          custom
        >
          <button
            class="fgh-button mini fgh-button--primary-outline-grey btn-login"
            @click="navigate"
          >
            Login
          </button>
        </router-link>
        <router-link
          v-if="$store.state.isLogged"
          v-slot="{ navigate }"
          :to="{ name: 'feed-feed' }"
          custom
        >
          <button
            class="fgh-button mini fgh-button--primary btn-back-to-hub"
            @click="navigate"
          >
            Back to hub
          </button>
        </router-link>
      </el-col>
      <el-col
        class="btns-nav hidden-md-and-up"
        :sm="18"
      >
        <vsvg-icon
          data="@assetsIcon/collapse-icon.svg"
          scale="1"
          original
          class="collapse-icon hidden-lg-and-up pointer"
          @click="showMobileMenu = true"
        />
      </el-col>
    </el-row>
    <register-dialog />
    <transition name="slide">
      <mobile-navigation-sidebar
        v-if="showMobileMenu"
        v-on-click-outside="() => { showMobileMenu = false }"
        @on-close="showMobileMenu = false"
      />
    </transition>
  </div>
</template>

<script>
import MobileNavigationSidebar from './mobile-navigation-sidebar'
import { mixin as onClickOutside } from 'vue-on-click-outside'
import RegisterDialog from '@/modules/auth/components/register-dialog'

export default {
  name: 'PublicNavigationBar',

  components: {
    MobileNavigationSidebar,
    RegisterDialog
  },

  mixins: [
    onClickOutside
  ],

  data: () => ({
    activeMenuItem: 'public-home',
    showMobileMenu: false,
  }),
}
</script>

<style lang="scss" scoped>
.btn-register {
  margin-right: 10px;
}
</style>
