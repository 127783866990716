<template>
  <div class="banner-wrapper">
    <div class="primary-banner">
      <p v-if="subtitle">
        {{ subtitle }}
      </p>
      <h1>{{ title }}</h1>
    </div>
    <div
      v-if="secondaryTitle"
      class="secondary-banner"
    >
      {{ secondaryTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthBannerComponent',

  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ''
    },
    secondaryTitle: {
      type: String,
      default: ''
    },
  }
}
</script>

<style lang="scss">
.banner-wrapper {
  text-align: center;
  .primary-banner {
    background-color: $banner-primary-bg-color;
    color: $banner-primary-fg-color;

    p {
      margin: 0;
      padding-top: 20px;
      margin-bottom: -10px;
    }

    h1 {
      margin: 0;
      padding-top: 20px;
      padding-bottom: 20px;
      @media (max-width: 480px) {
        font-size: 34px;
      }
    }
  }
  .secondary-banner {
    background-color: $banner-secondary-bg-color;
    color: $banner-secondary-fg-color;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
</style>
