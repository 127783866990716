<template>
  <div class="navbar-profile">
    <el-dropdown
      class="el-dropdown--profile"
      trigger="click"
    >
      <span class="avatar-arrow">
        <img
          class="avatar"
          :src="$store.state.avatar"
        />
        <i class="el-icon-arrow-down"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="$router.push({ name: 'account-profile' })">
          Profile
        </el-dropdown-item>
        <el-dropdown-item @click.native="$router.push({ name: 'account-account' })">
          Account
        </el-dropdown-item>
        <!-- <el-dropdown-item @click.native="$router.push({ name: 'account-interests' })">
          Interests
        </el-dropdown-item> -->
        <el-dropdown-item @click.native="logout">
          Logout
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import authApi from '@/modules/auth/services/auth.api'

export default {
  methods: {
    logout() {
      authApi
        .logout(this.$store.state.token)
        .then(() => {
          this.$store.commit('LOGOUT_USER');
          setTimeout(() => {
            this.$router.push('/')
          }, 100)
        })
    }
  },
}
</script>
