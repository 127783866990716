import lazyLoader from '@/router/lazy-loader'
import AppLayout from '@/layouts/app-layout/layout'

export const leaderboardRoutes = [
  {
    path: '/leaderboard',
    component: AppLayout,
    redirect: 'leaderboard',
    children: [
      {
        name: 'leaderboard-leaderboard',
        path: '',
        component: lazyLoader('leaderboard', 'leaderboard'),
        meta: {
          title: 'Leaderboard',
          auth: true,
          enterprise: true,
        }
      },
    ]
  }
]
