import lazyLoader from '@/router/lazy-loader'
import AppLayout from '@/layouts/app-layout/layout'

export const feedRoutes = [
  {
    path: '/feed',
    component: AppLayout,
    redirect: 'feed',
    children: [
      {
        name: 'feed-welcome',
        path: 'welcome',
        component: lazyLoader('feed', 'welcome'),
        meta: {
          title: 'Welcome',
          auth: true,
        }
      },
      {
        name: 'feed-feed',
        path: '',
        component: lazyLoader('feed', 'feed'),
        meta: {
          title: 'Feed',
          auth: true,
        }
      },
      {
        name: 'feed-article',
        path: 'article/:id',
        component: lazyLoader('feed', 'article-detail'),
        meta: {
          title: 'Article',
          auth: true,
        }
      },
      {
        name: 'feed-event',
        path: 'event/:id',
        component: lazyLoader('feed', 'event-detail'),
        meta: {
          title: 'Event',
          auth: true,
        }
      },
      {
        name: 'feed-reward',
        path: 'reward/:id',
        component: lazyLoader('feed', 'reward-detail'),
        meta: {
          title: 'Reward',
          auth: true,
        }
      },
    ]
  }
]
