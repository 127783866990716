<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="720px"
    :close-on-click-modal="false"
    class="register-dialog"
  >
    <div
      slot="title"
      class="d-flex align-items-center custom-title-wrap"
    >
      <button
        class="btn-dialog-close"
        @click="dialogVisible=false"
      >
        <vsvg-icon
          data="@assetsIcon/close-icon.svg"
          scale="1"
          class="close-icon"
          :color="`${brand.styles.primary} #ffffff`"
        />
      </button>
    </div>
    <div class="el-dialog--content">
      <el-row :gutter="32">
        <el-col
          :md="10"
          class="el-col--get-app-image hidden-sm-and-down"
        >
          <img
            src="/images/mobile-full.png"
            srcset="/images/mobile-full@2x.png 2x"
            alt="Image of FGH Mobile App"
          />
        </el-col>
        <el-col
          :md="14"
          class="el-col--get-app-text"
        >
          <div class="get-app-image-text-wrapper d-flex align-items-center">
            <img
              src="/images/mobile-full.png"
              srcset="/images/mobile-full@2x.png 2x"
              alt="Image of FGH Mobile App"
              class="hidden-md-and-up"
            />
            <h2>GET THE APP</h2>
          </div>
          <p>Download the FREE {{ brand.name }} app to start recording your activities, connecting with friends and joining challenges.</p>
          <div class="d-flex download-app">
            <a
              target="_blank"
              :href="brand.appStoreLink"
            >
              <vsvg-icon
                data="@assetsIcon/app-store-icon.svg"
                scale="1.5"
                original
              />
            </a>
            <a
              target="_blank"
              :href="brand.playStoreLink"
            >
              <vsvg-icon
                data="@assetsIcon/play-market-icon.svg"
                scale="1.5"
                original
              />
            </a>
          </div>
        </el-col>
      </el-row>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'RegisterDialog',

  data: () => ({
    dialogVisible: false,
  }),

  mounted() {
    this.$events.listen('showRegisterDialog', (eventData) => {
      this.dialogVisible = true
    })
  },
}
</script>

<style lang="scss">
.register-dialog {
  .el-dialog {
    border-radius: 25px;
    @include retina-background-image('/images/register-dialog-footer', 'png', 100%, 100%);
    background-position: bottom !important;
    background-size: contain !important;
    background-repeat: no-repeat;
    width: 50%;
    @media (max-width: 767px) {
      width: 95% !important;
      background-image: none;
    }
  }

  .custom-title-wrap {
    .el-dialog__title {
      font-size: 18px;
      font-weight: bold;
    }

    .btn-dialog-close {
      margin-left: auto;
      background: none;
    }
  }

  .el-dialog__header {
    padding: 40px 40px 0;
    .el-icon-close {
      display: none;
    }
  }

  .el-dialog__body {
    padding: 0 70px 30px;
    .el-col--get-app-image {
      display: flex;
      img {
        margin: 0 10px 50px auto;
      }
    }
    .el-col--get-app-text {
      word-break: normal;
      .get-app-image-text-wrapper {
        img {
          max-width: 70px;
          margin-right: 20px;
        }
      }
      h2 {
        color: $primary-color;
        font-size: 28px;
      }
      p {
        font-size: 16px;
        line-height: 21px;
        margin: 30px 0 30px;
      }
      .download-app {
        a {
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
