<!-- Index Component -->
<template>
  <div class="wrapper">
    <app-header />
    <common-content />
  </div>
</template>

<script>
import AppHeader from './header'
import CommonContent from '../common-layout/content'

export default {
  name: 'AppLayout',

  components: {
    'app-header': AppHeader,
    'common-content': CommonContent,
  },
}
</script>
