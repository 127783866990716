a<!-- Index Component -->
<template>
  <div class="wrapper">
    <auth-header />
    <auth-banner
      :title="$route.meta.bannerTitle || ''"
      :subtitle="$route.meta.bannerSubtitle || ''"
      :secondary-title="$route.meta.bannerSecondaryTitle || ''"
    />
    <div class="container-fluid auth-content-container">
      <common-content />
    </div>
    <common-footer />
  </div>
</template>

<script>
import AuthHeader from '@/layouts/auth-layout/header'
import AuthBanner from '@/layouts/auth-layout/banner'
import CommonContent from '@/layouts/common-layout/content'
import CommonFooter from '@/layouts/common-layout/footer'

export default {
  name: 'AuthLayout',

  components: {
    AuthHeader,
    AuthBanner,
    CommonContent,
    CommonFooter,
  },
}
</script>

<style lang="scss">
.auth-content-container {
  background-color: $white-color;

  .auth-form {
    margin: 0 auto;
    padding: 40px 0;
    text-align: center;

    .form-header {
      text-align: left;
      margin: 0 0 30px 0;
    }

    label {
      line-height: 20px;
      color: $primary-grey-color;
      font-size: 12px;

      &:before {
        content: '';
        margin-right: 0;
      }
    }

    input {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid $border-color;
      font-size: 14px;
      padding-left: 0;
      background: transparent;
      color: $primary-grey-color;
      &::placeholder {
        color: $input-placeholder-color;
      }
      &:focus {
        border-bottom: 1px solid $primary-color;
      }
    }

    .el-select {
      width: 100%;
    }
  }
}
</style>
