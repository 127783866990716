<template>
  <div class="container-fluid">
    <div class="container">
      <div class="auth-header d-flex align-items-center">
        <router-link
          :to="{ name: 'public-home' }"
        >
          <vsvg-icon
            data="@assetsIcon/brand/logo-icon.svg"
            scale="1"
            original
          />
        </router-link>
        <div class="nav-buttons-wrapper">
          <!-- <router-link
            v-if="$route.name === 'auth-signup'"
            :to="{ name: 'auth-login' }"
          >
            Already a member? Login
          </router-link> -->
          <a
            v-if="$route.name === 'auth-login'"
            href="#"
            @click="$events.emit('showRegisterDialog')"
          >
            Not a member? Join now
          </a>
          <router-link
            v-if="$route.name === 'auth-forgot-password'"
            :to="{ name: 'auth-login' }"
          >
            Remember your details? Login
          </router-link>
          <a
            v-if="$route.name === 'auth-verify-email'"
            @click="onLogout"
          >
            Already verified? Login
          </a>
        </div>
      </div>
    </div>
    <register-dialog />
  </div>
</template>

<script>
import store from '@/store'
import RegisterDialog from '@/modules/auth/components/register-dialog'

export default {
  components: {
    RegisterDialog
  },

  methods: {
    onLogout() {
      store.commit('LOGOUT_USER')
      this.$router.push({ name: 'auth-login' })
    }
  }
}
</script>

<style lang="scss">
.auth-header {
  height: 80px;
  border-bottom: 1px solid #EDECED;

  .nav-buttons-wrapper {
    font-size: 14px;
    margin-left: auto;
  }
}
</style>
