import brandConfig from "@/brand.config"
import store from "@/store"

const documentTitleSetter = (to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} - ${brandConfig.name}`
  } else {
    document.title = brandConfig.name
  }
  next()
}

const guestOnly = (to, from, next) => {
  if (to.meta && to.meta.guest && (store.state.isLogged || store.state.mustVerify)) {
    next({ name: store.state.mustVerify ?  'auth-verify-email' : 'feed-feed' })
  } else {
    next()
  }
}

const authOnly = (to, from, next) => {
  if (to.meta && to.meta.auth && !store.state.isLogged) {
    store.state.redirectUrl = to
    next({ name: 'auth-login' })
  } else {
    next()
  }
}

const brandsOnly = (to, from, next) => {
  if (to.meta && to.meta.brands && !to.meta.brands.includes(process.env.VUE_APP_BRAND_KEY)) {
    next({ name: 'auth-login' })
  } else {
    next()
  }
}

const enterpriseOnly = (to, from, next) => {
  if (to.meta && to.meta.enterprise && !store.getters.companyIsEnterprise) {
    next({ name: 'auth-login' })
  } else {
    next()
  }
}

export default {
  documentTitleSetter,
  guestOnly,
  authOnly,
  brandsOnly,
  enterpriseOnly,
}
