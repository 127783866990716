<template>
  <li>
    <div
      class="notif-block"
      @click="readNote(notification)"
    >
      <div class="notif-img">
        <vsvg-icon
          data="@assetsIcon/brand/nudge-icon.svg"
          scale="1"
          :color="brand.styles.primary"
        />
      </div>
      <p class="title">
        {{ notification.title }}
      </p>
      <span
        v-if="notification.is_read === 0"
        class="unread-dot"
      ></span>
    </div>
  </li>
</template>

<script>
import accountApi from '@/modules/account/services/account.api'

export default {
  name: 'NotificationItem',

  props: {
    notification: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    readNote(notification) {
      accountApi
        .notifications
        .read(notification.id)
        .then((response) => {
          this.$store.commit("UPDATE_NOTS", (this.$store.state.unreadNots - 1))
          this.$emit('on-read')
        })
    }
  }
}
</script>

<style lang="scss" scoped>
li {
    font-size: 13px;

    &:last-child {
        .notif-block {
            border-bottom: none;
        }
    }

    &:hover {
        background: $primary-color-lighter;
    }

    .notif-block {
        display: flex;
        align-items: center;
        cursor: pointer;
        border-bottom: 1px solid $border-color;
        padding-bottom: 18px;
        padding: 18px 32px;
        @media (max-width: 320px) {
            padding: 18px 12px 18px 12px;
        }
        .notif-img {
            width: 34px;
            margin-bottom: auto;
            .user-img {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            position: relative;
            img {
                width: 14px;
                position: absolute;
                right: -5px;
                top: 19px;
            }
            }
            svg {
            //vertical-align: middle;
            display: block;
            }
        }
        .title {
            margin: 0 5px 0 14px;
            color: $body-text-color;
            font-size: 13px;
        }
        .unread-dot {
            background-color: $primary-color;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            margin-left: auto;
            flex-shrink: 0;
        }
    }
}
</style>
