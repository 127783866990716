import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

const state = {
  isLogged: !!localStorage.getItem('vuex'),
  isCookiesAccepted: !!localStorage.getItem('cookie:accepted'),
  mustVerify: false,
  id: null,
  token: null,
  email: null,
  firstName: null,
  lastName: null,
  avatar: null,
  location: null,
  team: null,
  company: null,
  teamVal: null,
  teamLeader: null,
  teamParentId: null,
  totalPoints: null,
  unreadNots: null,
  stravaAuthorized: null,
  groupLabel: null,
  redirectUrl: null,
};

const getters = {
  companyIsEnterprise: state => {
    if (state.company && state.company.type) {
      return state.company.type.type == 'enterprise'
    }

    return true
  }
}

const mutations = {
  LOGIN_USER (state, data) {
    state.isLogged = true;
    state.mustVerify = false;
    state.id = data.id;
    state.token = data.token;
    state.email = data.email;
    state.firstName = data.first_name;
    state.lastName = data.last_name;
    state.avatar = data.avatar;
    state.stravaAuthorized = data.strava_authorized;
    state.groupLabel = data.group_label;
    if (typeof data.location != "undefined") state.location = data.location.id;
    if (typeof data.company != "undefined") state.company = data.company;
    if (typeof data.team != "undefined") state.team = data.team.id;
    if (typeof data.team != "undefined") {
      state.teamVal = data.team.name;
      state.teamLeader = data.team.is_leader;
      state.teamParentId = data.team.parent_team_id
    }
  },

  LOGIN_UNVERIFIED_USER (state, data) {
    state.mustVerify = true
    state.id = data.id
    state.email = data.email
  },

  SET_COOKIE_ACCEPTED (state) {
    state.isCookiesAccepted = true
  },

  UPDATE_POINTS (state,data) {
    state.totalPoints = data.total_points;
  },

  UPDATE_NOTS (state, data) {
    state.unreadNots = data
  },

  LOGOUT_USER (state) {
    window.localStorage.clear();
    state.mustVerify = false;
    state.isLogged = false;
    state.isCookiesAccepted = false;
    state.id = null;
    state.token = null;
    state.email = null;
    state.firstName = null;
    state.lastName = null;
    state.avatar = null;
    state.location = null;
    state.company = null;
    state.team = null;
    state.teamVal = null;
    state.teamLeader = null;
    state.teamParentId = null;
    state.totalLikes = null;
    state.totalPoints = null;
    state.volunteerLogged = null;
    state.eventLogged = null;
    state.momentLogged = null;
    state.runLogged = null;
    state.rideLogged = null;
    state.walkLogged = null;
    state.stravaAuthorized = null;
  },

  CHANGE_EMAIL (state, email) {
    state.email = email
  },

  CHANGE_AVATAR (state, data) {
    state.avatar = data
  }
};

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state,
  getters,
  mutations,
  plugins: [createPersistedState()]
})
