const brandConfig = {
  feelgoodhub: {
    'name': 'Feel Good Hub',
    'scssVarsEntryPoint': '@import "~@/styles/brands/feelgoodhub/vars/index.scss";',
    'logoPath': '@assetsIcon/brand/feelgoodhub/logo-icon.svg',
    'contactEmail': 'support@thefeelgoodhub.com',
    'appStoreLink': 'https://itunes.apple.com/us/app/feel-good-hub/id1585844178?ls=1&mt=8',
    'playStoreLink': 'https://play.google.com/store/apps/details?id=ltd.ember.feelgoodhub',
    'termsLink': 'https://support.thefeelgoodhub.com/portal/en/kb/articles/terms-of-service',
    'privacyLink': 'https://support.thefeelgoodhub.com/portal/en/kb/articles/privacy-policy',
    'cookieLink': 'https://support.thefeelgoodhub.com/portal/en/kb/articles/cookie-policy',
    lang: {
      'joinHubFooterDescription': 'Feel Good Hub supports your physical and mental weel-being.',
      'signUpMarketingText': 'As a thefeelgoodhub.com user I am happy for Feel Good Hub to send me health and wellbeing news, content, offers and tips by email.',
      'profileInviteButtonText': 'Invite friends',
      'profileInvitePopoverText': 'Invite a friend',
      'welcomeInviteText': 'Invite a friend',
    },
    styles: {
      'primary': '#e0004d',
    }
  },
  getactivehub: {
    'name': 'Get Active Hub',
    'scssVarsEntryPoint': '@import "~@/styles/brands/getactivehub/vars/index.scss";',
    'logoPath': '@assetsIcon/brand/getactivehub/logo-icon.svg',
    'contactEmail': 'support@getactive.co.uk',
    'appStoreLink': 'https://itunes.apple.com/us/app/our-cycle-hub/id1376576249?ls=1&mt=8',
    'playStoreLink': 'https://play.google.com/store/apps/details?id=ltd.ember.ourcyclehub',
    'termsLink': '/terms-conditions/terms-conditions',
    'privacyLink': '/terms-conditions/privacy-policy',
    'cookieLink': '/terms-conditions/cookie-policy',
    lang: {
      'joinHubFooterDescription': 'Get Active Hub supports colleagues physical and mental well-being.',
      'signUpMarketingText': 'As a getactivehub.co.uk user I am happy for British Cycling and HSBC UK to send me cycling news, content, offers and tips by email.',
      'profileInviteButtonText': 'Invite colleagues',
      'profileInvitePopoverText': 'Invite a colleague',
      'welcomeInviteText': 'Invite a HSBC UK colleague',
    },
    styles: {
      'primary': '#DB0011',
    }
  },
}

module.exports = brandConfig[process.env.VUE_APP_BRAND_KEY]
