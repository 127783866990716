<template>
  <div class="header">
    <div class="container-fluid">
      <div class="container">
        <navigation-bar
          @on-notification-bell-clicked="showNotifications = true"
        />
      </div>
    </div>
    <transition name="slide">
      <notifications-sidebar
        v-if="showNotifications"
        v-on-click-outside="() => { showNotifications = false }"
        @on-close="showNotifications = false"
      />
    </transition>
  </div>
</template>

<script>
import NavigationBar from './navigation-bar'
import NotificationsSidebar from './notifications-sidebar'
import { mixin as onClickOutside } from 'vue-on-click-outside'

export default {
  components: {
    NavigationBar,
    NotificationsSidebar,
  },

  mixins: [
    onClickOutside
  ],

  data: () => ({
    showNotifications: false,
  }),
}
</script>

<style lang="scss">
.header {
    background-color: $white-color;
    border-bottom: 1px solid #EDECED;

    .site-navigation {
        display: flex;
        align-items: center;
        min-height: 80px;

        .navbar-brand {
            display: flex;
            align-self: center;
        }

        .navbar {
            list-style: none;
            border: none !important;

            .el-menu-item {
                height: auto;
                line-height: normal;
                border-bottom: none !important;
                border-bottom-color: transparent !important;
                font-weight: bold;
                padding: 0 8px 0 0;
                font-size: 16px;
                color: $body-text-color;
                display: flex;
                align-items: center;

                &.is-active {
                    border-bottom: none !important;
                }

                a {
                    padding: 10px 20px;
                    border-radius: 90px;
                    svg {
                        margin-right: 10px;
                    }
                    &.router-link-exact-active {
                        background: #EDECED;
                    }
                }
            }
        }

        .btns-nav {
            text-align: right;
        }
    }
}
</style>

<style lang="scss" scoped>
.slide-enter-active {
  transition: all .3s ease;
}
.slide-leave-active {
  transition: all .3s ease;
}
.slide-enter, .slide-leave-to {
  transform: translate(10px);
  opacity: 0;
}
</style>
