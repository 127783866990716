<template>
  <div class="notifications-sidebar">
    <div class="bell-heading-wrapper">
      <div class="bell-wrapper">
        <vsvg-icon
          data="@assetsIcon/bell-icon.svg"
          scale="1"
          original
        />
        <el-badge
          v-if="$store.state.unreadNots > 0"
          :value="$store.state.unreadNots > 99 ? '99+' : $store.state.unreadNots"
        />
      </div>
      <h2>Notifications</h2>
      <vsvg-icon
        data="@assetsIcon/close-icon.svg"
        scale="1"
        class="close-icon"
        :color="`${brand.styles.primary} #ffffff`"
        @click="$emit('on-close')"
      />
    </div>
    <div class="actions">
      <el-button @click="onNotificationAction('read_all')">
        Mark all as read
      </el-button>
      <span class="line">|</span>
      <el-button @click="onNotificationAction('del_all')">
        Delete all
      </el-button>
    </div>
    <div class="notifications-list">
      <div
        v-if="loading"
        v-loading="loading"
        class="container"
        style="min-height: 90px"
      ></div>
      <ul>
        <notification-item
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
          @on-read="notification.is_read = 1; $emit('on-close')"
        />
      </ul>
    </div>
  </div>
</template>

<script>
import NotificationItem from './notification-item'
import accountApi from '@/modules/account/services/account.api'

export default {
  name: "NotificationsSidebar",

  components: {
    NotificationItem,
  },

  data: () => ({
    loading: true,
    notifications: [],
  }),

  created() {
    this.getNotifications()
  },

  methods: {
    getNotifications() {
      this.loading = true

      accountApi
        .notifications
        .index()
        .then((response) => {
          this.loading = false
          this.notifications.push(...response.data.data)
        })
    },
    onNotificationAction(action) {
      accountApi
        .notifications[action === 'read_all' ? 'readAll' : 'deleteAll']()
        .then((response) => {
          this.$store.commit("UPDATE_NOTS", null)
          if (action == 'read_all') {
            this.notifications.map( n => n.is_read = true )
          } else {
            this.notifications = []
          }
        })
    },
  }
}
</script>

<style lang="scss">
.notifications-sidebar {
  width: 320px;
  height: 100%;
  position: fixed;
  z-index: 9999;
  top: 0;
  right: 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.25);

  @media (max-width: 425px) {
    width: 80%;
  }

  .bell-heading-wrapper {
    display: flex;
    align-items: center;
    padding: 25px 32px 0 32px;
    @media (max-width: 320px) {
      padding: 25px 12px 0 12px;
    }
    
    .bell-wrapper {
      position: relative;
      margin-right: 20px;
       .el-badge {
        margin-right: 0;
        bottom: -10px;
        right: 7px;
        .el-badge__content {
          border-radius: 15px;
          border-width: 2px;
          background-color: $status-red-color;
          transform: none;
        }
      }
    }

    .close-icon {
      cursor: pointer;
      margin-left: auto;
    }
  }


  h2 {
    margin: 0;
    display: inline-block;
  }

  .title {
    span {
      margin-left: 17px;
      font-size: 16px;
    }
    .close {
      text-align: right;
      svg {
        cursor: pointer;
      }
    }
  }

  .actions {
    padding: 29px 32px;
    @media (max-width: 320px) {
      padding: 29px 12px;
    }
    p {
      margin: 0;
    }
    .line {
      margin: 0 10px;
      font-size: 12px;
      color: $primary-color;
    }
    button {
      padding: 0;
      border: none;
      color: $primary-color;
      font-size: 12px;
    }
  }

  .notifications-list ul {
    margin: 0;
    padding: 0;
    list-style: none; 
  }

  .el-loading-parent--relative {
    width: auto;
    .el-loading-mask {
      width: 42px;
      margin: 0 auto;
    }
    .el-loading-spinner {
      width: auto;
    }
  }
}
</style>
