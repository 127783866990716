import lazyLoader from '@/router/lazy-loader'
import AppLayout from '@/layouts/app-layout/layout'

export const accountRoutes = [
  {
    path: '/profile',
    redirect: '/profile',
    component: AppLayout,
    children: [
      {
        name: 'account-profile',
        path: '',
        component: lazyLoader('account', 'profile'),
        meta: {
          title: 'Profile',
          auth: true,
        }
      },
    ]
  },
  {
    path: '/account',
    redirect: '/account',
    component: AppLayout,
    children: [
      {
        name: 'account-account',
        path: '',
        component: lazyLoader('account', 'account'),
        meta: {
          title: 'Account Settings',
          auth: true,
        }
      },
    ]
  },
  // {
  //   path: '/interests',
  //   redirect: '/interests',
  //   component: AppLayout,
  //   children: [
  //     {
  //       name: 'account-interests',
  //       path: '',
  //       component: lazyLoader('account', 'interests'),
  //       meta: {
  //         title: 'Interests',
  //         auth: true,
  //       }
  //     },
  //   ]
  // }
]
