import http from '@/services/http/app-http-driver'

export default {
  profile() {
    return http({ url: '/users/profile', method: 'get' })
  },
  updateProfile(data = {}) {
    return http({ url: '/users/profile', method: 'post', data })
  },
  updateProfileAvatar(data = {}) {
    return http({ url: '/users/profile/avatar', method: 'post', headers: { 'Content-Type': 'multipart/form-data' }, data })
  },
  gamers() {
    return http({ url: '/gamers', method: 'get' })
  },
  points(params = {}) {
    return http({ url: '/users/points', method: 'get', params })
  },
  rewards(params = {}) {
    return http({ url: `/users/rewards`, method: 'get', params })
  },
  achievements(params = {}) {
    return http({ url: `/challenges/achievements`, method: 'get', params })
  },
  shareAchievement(id) {
    return http({ url: `/challenges/achievements/${id}/share`, method: 'get' })
  },
  invite(email) {
    return http({ url: `/users/invite`, method: 'post', data: { email } })
  },
  notifications: {
    index() {
      return http({ url: '/notifications', method: 'get', params: { client_id: 2 } })
    },
    read(id) {
      return http({ url: `notifications/${id}/read`, method: 'get' })
    },
    readAll() {
      return http({ url: '/notifications/read-all', method: 'get' })
    },
    deleteAll() {
      return http({ url: '/notifications/delete-all', method: 'get' })
    },
  },
  strava: {
    authorize(code) {
      return http({ url: '/strava/authorize', method: 'post', data: { code } })
    },
    deauthorize() {
      return http({ url: '/strava/deauthorize', method: 'delete' })
    },
  }
}
