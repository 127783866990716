import Vue from 'vue'
import VueEvents from 'vue-events'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import VueMeta from 'vue-meta'
import VueGtm from 'vue-gtm'
import VueProgressBar from 'vue-progressbar'
import { VueSvgIcon } from '@yzfe/vue-svgicon'

import App from '@/app'
import router from '@/router'
import store from '@/store'

import '@yzfe/svgicon/lib/svgicon.css'

Vue.use(VueEvents)
Vue.use(ElementUI, { locale });

Vue.use(VueGtm, {
  id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID, // Your GTM single container ID or array of container ids ['GTM-xxxxxxx', 'GTM-yyyyyyy']
  queryParams: { // Add url query string when load gtm.js with GTM ID (optional)
    gtm_auth: process.env.VUE_APP_GOOGLE_TAG_MANAGER_AUTH,
    gtm_preview: process.env.VUE_APP_GOOGLE_TAG_MANAGER_PREVIEW,
    gtm_cookies_win: 'x',
  },
  enabled: store.state.isCookiesAccepted, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  debug: false, // Whether or not display console logs debugs (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
  // ignoredViews: ['homepage'] // If router, you can exclude some routes name (case insensitive) (optional)
})

Vue.use(VueMeta)

import brandConfig from '@/brand.config.js'

Vue.use(VueProgressBar, {
  color: brandConfig.styles.primary,
  failedColor: brandConfig.styles.primary,
  thickness: '2px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})

Vue.mixin({
  data() {
    return {
      brand: brandConfig,
    }
  },
  methods: {
    isActiveBrand(brands = []) {
      return brands.includes(process.env.VUE_APP_BRAND_KEY)
    }
  }
})

Vue.component('VsvgIcon', VueSvgIcon)

Vue.config.productionTip = false

export default new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
