import lazyLoader from '@/router/lazy-loader'
import PublicLayout from '@/layouts/public-layout/layout'

export const publicRoutes = [
  {
    path: '/',
    component: PublicLayout,
    redirect: 'home',
    children: [
      {
        name: 'public-home',
        path: 'home',
        component: lazyLoader('public', 'home'),
        meta: {
          title: 'Home'
        }
      },
      {
        name: 'public-why',
        path: 'why',
        component: lazyLoader('public', 'why-should-join'),
        meta: {
          title: 'Why Should I Join?'
        }
      },
      {
        name: 'public-article',
        path: 'landing/article/:id',
        component: lazyLoader('feed', 'article-detail'),
        props: {
          isPublic: true
        },
        meta: {
          title: 'Article',
        }
      },
      {
        name: 'public-event',
        path: 'landing/event/:id',
        component: lazyLoader('feed', 'event-detail'),
        props: {
          isPublic: true
        },
        meta: {
          title: 'Event',
        }
      },
      {
        name: 'public-how-works',
        path: 'how-works',
        component: lazyLoader('public', 'how-works'),
        meta: {
          title: 'How Points Work'
        }
      },
      {
        name: 'public-faq',
        path: 'faq',
        component: lazyLoader('public', 'faq'),
        meta: {
          title: 'FAQ\'s',
          brands: ['getactivehub']
        }
      },
      {
        name: 'public-legal',
        path: 'terms-conditions',
        component: lazyLoader('public', 'legal'),
        redirect: 'public-terms',
        children: [
          {
            name: 'public-terms',
            path: 'terms-conditions',
            component: lazyLoader('public', `legals/brands/${process.env.VUE_APP_BRAND_KEY}/terms-conditions`),
            meta: {
              title: 'Terms & Conditions',
              brands: ['getactivehub']
            }
          },
          {
            name: 'public-privacy',
            path: 'privacy-policy',
            component: lazyLoader('public', `legals/brands/${process.env.VUE_APP_BRAND_KEY}/privacy-policy`),
            meta: {
              title: 'Privacy Policy',
              brands: ['getactivehub']
            }
          },
          {
            name: 'public-cookie',
            path: 'cookie-policy',
            component: lazyLoader('public', `legals/brands/${process.env.VUE_APP_BRAND_KEY}/cookie-policy`),
            meta: {
              title: 'Cookie Policy',
              brands: ['getactivehub']
            }
          },
        ]
      },
    ]
  },
]
