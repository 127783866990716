<template>
  <div class="mobile-navigation-sidebar">
    <ul>
      <li
        class="logo"
        @click="onItemClick({ name: 'feed-feed', params: { isActiveFilter: 1 } })"
      >
        <vsvg-icon
          data="@assetsIcon/brand/logo-icon.svg"
          scale="1"
          original
        />
      </li>
      <li @click="onItemClick({ name: 'feed-feed', params: { isActiveFilter: 1 } })">
        <div class="icon-wrapper">
          <vsvg-icon
            data="@assetsIcon/nav-item/feed-icon.svg"
            scale="1"
            :color="brand.styles.primary"
          />
        </div>
        <span>Feed</span>
      </li>
      <li
        v-if="$store.getters.companyIsEnterprise"
        @click="onItemClick({ name: 'challenge-challenges' })"
      >
        <div class="icon-wrapper">
          <vsvg-icon
            data="@assetsIcon/nav-item/challenge-icon.svg"
            scale="1"
            :color="brand.styles.primary"
          />
        </div>
        <span>Challenges</span>
      </li>
      <li
        v-if="$store.getters.companyIsEnterprise"
        @click="onItemClick({ name: 'leaderboard-leaderboard' })"
      >
        <div class="icon-wrapper">
          <vsvg-icon
            data="@assetsIcon/nav-item/leaderboard-icon.svg"
            scale="1"
            :color="brand.styles.primary"
          />
        </div>
        <span>Leaderboard</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'MobileNavigationSidebar',

  methods: {
    onItemClick(to = null) {
      if (to) {
        this.$router.push(to)
      }

      this.$emit('on-close')
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active, .slide-leave-active {
  transition: transform .5s;
}
.slide-enter, .slide-leave-to {
  transform: translateX(-100%);
}
</style>

<style lang="scss">
.mobile-navigation-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  min-width: 180px;
  height: 100%;
  padding: 30px;
  background-color: $white-color;
  transition: 0.6s all;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.25);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      cursor: pointer; 
      &:not(.logo) {
        display: flex;
        align-items: center;
        margin-top: 20px;
        padding: 10px 16px;
        border-radius: 90px;
        font-weight: bold;
        .icon-wrapper {
          display: flex;
          justify-content: center;
          width: 40px;
        }
        span {
          margin-left: 15px;
        }
        &:hover {
          background: $border-color;
        }
      }
    }
  }
}
</style>
