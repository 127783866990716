<template>
  <div id="app">
    <router-view />
    <vue-progress-bar />
    <cookie-law
      button-text="Accept"
      button-class="fgh-button lg fgh-button--primary"
      @accept="onCookieAccept"
    >
      <div slot="message">
        This website uses cookies to ensure you get the best experience, for more information
        <router-link
          v-if="isActiveBrand(['feelgoodhub'])"
          class="router-link"
          :to="{ name: 'public-cookie' }"
          f
        >
          Click Here
        </router-link>
        <a
          v-else
          class="router-link"
          :href="brand.cookieLink"
        >
          Click Here
        </a>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import store from "./store"
import accountApi from '@/modules/account/services/account.api'
import CookieLaw from 'vue-cookie-law'

export default {
  name: 'App',
  components:{
    CookieLaw
  },
  watch:{
    $route(){
      if (store.state.isLogged) {
        accountApi
          .gamers()
          .then(response => {
            store.commit("UPDATE_POINTS", response.data);
          })

        accountApi
          .notifications
          .index()
          .then(response => {
            const result = response.data.data.filter(not => not.is_read == 0);
            store.commit("UPDATE_NOTS", result.length);
          })
      }
    }
  },
  methods: {
    onCookieAccept() {
      store.commit('SET_COOKIE_ACCEPTED')
    },
  }
}
</script>
