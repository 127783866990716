a<!-- Index Component -->
<template>
  <div class="wrapper">
    <public-header />
    <public-content />
    <common-join-hub v-if="!$store.state.isLogged" />
    <common-footer />
  </div>
</template>

<script>
import PublicHeader from './header'
import PublicContent from '../common-layout/content'
import CommonJoinHub from '../common-layout/join-hub'
import CommonFooter from '../common-layout/footer'

export default {
  name: 'PublicLayout',

  components: {
    'public-header': PublicHeader,
    'public-content': PublicContent,
    'common-join-hub': CommonJoinHub,
    'common-footer': CommonFooter,
  },
}
</script>
