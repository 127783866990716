import lazyLoader from '@/router/lazy-loader'
import AppLayout from '@/layouts/app-layout/layout'

export const challengeRoutes = [
  {
    path: '/challenges',
    redirect: '/challenges',
    component: AppLayout,
    children: [
      {
        name: 'challenge-challenges',
        path: '',
        component: lazyLoader('challenge', 'challenges'),
        meta: {
          title: 'Challenges',
          auth: true,
          enterprise: true,
        }
      },
      {
        name: 'challenge-challenge-details',
        path: ':id',
        component: lazyLoader('challenge', 'challenge-details'),
        meta: {
          title: 'Challenge Details',
          auth: true,
          enterprise: true,
        }
      },
    ]
  }
]
