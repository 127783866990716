import Vue from 'vue'
import Router from 'vue-router'
import guards from '@/router/guards'
import { authRoutes } from '@/modules/auth/routes/auth.routes'
import { accountRoutes } from '@/modules/account/routes/account.routes'
import { challengeRoutes } from '@/modules/challenge/routes/challenge.routes'
import { feedRoutes } from '@/modules/feed/routes/feed.routes'
import { leaderboardRoutes } from '@/modules/leaderboard/routes/leaderboard.routes'
import { publicRoutes } from '@/modules/public/routes/public.routes'

const router =  new Router({
  mode: 'history',

  routes: [
    ...authRoutes,
    ...accountRoutes,
    ...challengeRoutes,
    ...feedRoutes,
    ...leaderboardRoutes,
    ...publicRoutes,
  ],

  scrollBehavior(to, from, savedPosition) {
    // Exists when Browser's back/forward pressed
    if (savedPosition) {
      return savedPosition
      // For anchors
    } else if (to.hash) {
      return { selector: to.hash }
      // By changing queries we are still in the same component, so "from.path" === "to.path" (new query changes just "to.fullPath", but not "to.path").
    } else if (from.path === to.path) {
      return {}
    }

    // Scroll to top
    return { x: 0, y: 0 }
  },
})

router.beforeEach(guards.guestOnly)
router.beforeEach(guards.authOnly)
router.beforeEach(guards.brandsOnly)
router.beforeEach(guards.enterpriseOnly)
router.beforeEach(guards.documentTitleSetter)

Vue.router = router
Vue.use(Router)

export default router
