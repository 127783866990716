import lazyLoader from '@/router/lazy-loader'
import AuthLayout from '@/layouts/auth-layout/layout'
import brandConfig from "@/brand.config"

export const authRoutes = [
  {
    path: '/',
    component: AuthLayout,
    redirect: 'home',
    children: [
      {
        name: 'auth-login',
        path: 'login',
        component: lazyLoader('auth', 'login'),
        meta: {
          guest: true,
          title: 'Login',
          bannerTitle: brandConfig.name,
          bannerSubtitle: 'Welcome to',
        }
      },
      // {
      //   name: 'auth-signup',
      //   path: 'sign-up',
      //   component: lazyLoader('auth', 'signup'),
      //   meta: {
      //     guest: true,
      //     title: 'Join',
      //     bannerTitle: brandConfig.name,
      //     bannerSubtitle: 'Welcome to',
      //     bannerSecondaryTitle: 'Joining takes less than 30 seconds, get started now.',
      //   }
      // },
      {
        name: 'auth-verify-email',
        path: 'verify-email',
        component: lazyLoader('auth', 'verify-email'),
        meta: {
          title: 'Verify Email',
          bannerTitle: 'Verify your email',
        }
      },
      {
        name: 'auth-forgot-password',
        path: 'forgot-password',
        component: lazyLoader('auth', 'forgot-password'),
        meta: {
          guest: true,
          title: 'Forgot Password',
          bannerTitle: 'Reset password',
        }
      },
    ]
  }
]
