import store from '@/store'

export default () => {
  let headers = {
    'ApiKey': process.env.VUE_APP_API_KEY,
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }

  if (store.state.isLogged) {
    headers['Authorization'] = `Bearer ${store.state.token}`
  }

  return headers
}
