<template>
  <div class="public-mobile-navigation-sidebar">
    <ul>
      <li
        class="logo"
        @click="onItemClick({ name: 'public-home', params: { isActiveFilter: 1 } })"
      >
        <vsvg-icon
          data="@assetsIcon/brand/logo-icon.svg"
          scale="1"
          original
        />
      </li>
      <li @click="onItemClick({ name: 'public-home', params: { isActiveFilter: 1 } })">
        Home
      </li>
      <li @click="onItemClick({ name: 'public-why' })">
        Why should I join?
      </li>
    </ul>
    <div class="button-wrapper">
      <button
        v-if="!$store.state.isLogged"
        class="fgh-button mini fgh-button--primary btn-register"
        @click="$events.emit('showRegisterDialog')"
      >
        Register
      </button>
      <router-link
        v-if="!$store.state.isLogged"
        v-slot="{ navigate }"
        :to="{ name: 'auth-login' }"
        custom
      >
        <button
          class="fgh-button mini fgh-button--primary-outline-grey btn-login"
          @click="navigate"
        >
          Login
        </button>
      </router-link>
      <router-link
        v-if="$store.state.isLogged"
        v-slot="{ navigate }"
        :to="{ name: 'feed-feed' }"
        custom
      >
        <button
          class="fgh-button mini fgh-button--primary btn-back-to-hub"
          @click="navigate"
        >
          Back to hub
        </button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileNavigationSidebar',

  methods: {
    onItemClick(to = null) {
      if (to) {
        this.$router.push(to)
      }

      this.$emit('on-close')
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-enter-active, .slide-leave-active {
  transition: transform .5s;
}
.slide-enter, .slide-leave-to {
  transform: translateX(-100%);
}
</style>

<style lang="scss">
.public-mobile-navigation-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  min-width: 180px;
  height: 100%;
  padding: 30px;
  background-color: $white-color;
  transition: 0.6s all;
  box-shadow: 0px 2px 50px rgba(0, 0, 0, 0.25);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      cursor: pointer; 
      &:not(.logo) {
        display: flex;
        align-items: center;
        margin-top: 10px;
        padding: 10px 16px;
        border-radius: 90px;
        font-weight: bold;
        &:hover {
          background: $border-color;
        }
        .router-link-active {
          color: $primary-color;
        }
      }
    }
  }
  .button-wrapper {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    button:last-child {
      margin-top: 10px;
    }
  }
}
</style>
