import app from '@/main'
import axios from 'axios'
import headers from './app-http-headers'

let instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
})

instance.interceptors.request.use(config => {
  config.headers = headers()
  app.$Progress.start()
  return config
})

instance.interceptors.response.use(response => {
  app.$Progress.finish()
  return response
}, error => {
  app.$Progress.finish()

  if (error && error.response) {
    if (error.response.status === 400) { // ApiKey Error
      // app.$store.dispatch('auth/autoLogout')
    } else if (error.response.status === 401) { // Auth Error
      // if (store.state.isLogged) {
      //   app.$store.dispatch('auth/logout')
      //     .then(() => {
      //       if (app.$route.name != 'login') {
      //         app.$router.push({ name: 'login' })
      //       }
      //     })
      // }
    } else if (error.response.status === 403) {
      // app.$store.dispatch('auth/logout')
      //   .then(() => {
      //     if (app.$route.name != 'login') {
      //       app.$router.push({ name: 'login' })
      //     }
      //   })
    }
  }

  return Promise.reject(error)
})

export default instance


// axios.interceptors.response.use((response) => response, (error) => {
//   if (store.state.isLogged && error && error.response && error.response.status === 401) {
//     store.commit('LOGOUT_USER');
//     setTimeout(()=> {
//       router.push('/')
//     }, 500)
//   }
//   // else if (error && error.status === 500) {
//   // Vue.router.push({name: 'error-500'}) // System Error
//   // }
//   else {
//     return Promise.reject(error) // Reject Error
//   }
// })