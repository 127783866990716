import http from '@/services/http/app-http-driver'

export default {
  login(email, password) {
    return http({ url: '/users/login', method: 'post', data: { email, password, client_id: 2 } })
  },
  socialLogin(token) {
    return http({ url: '/users/social-login', method: 'post', data: { token, client_id: 2 } })
  },
  logout(token) {
    return http({ url: '/users/logout', method: 'post', data: { user_token: token } })
  },
  register(data) {
    return http({ url: '/users/register', method: 'post', data: Object.assign(data, { client_id: 2 }) })
  },
  forgot(email) {
    return http({ url: '/users/forgotten-password', method: 'post', data: { email } })
  },
  resend(id, email) {
    return http({ url: '/users/resend-verification', method: 'post', data: { id, email } })
  },
  validateCompanyCode(code) {
    return http({ url: 'companies/validate-code', method: 'post', data: { company_code: code } })
  },
  companyLocations(params) {
    return http({ url: '/companies/locations', method: 'get', params })
  },
  companyTeams(params) {
    return http({ url: '/companies/teams', method: 'get', params })
  },
  companyQuestions(params = {}) {
    return http({ url: '/companies/questions', method: 'get', params })
  },
}
