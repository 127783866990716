<template>
  <div>
    <el-row class="site-navigation navigation-bar app-bar">
      <el-col
        :xl="4"
        :lg="4"
        :md="3"
        :sm="3"
      >
        <router-link
          :to="{ name: 'feed-feed', params: { isActiveFilter: 1 } }"
          class="navbar-brand"
        >
          <vsvg-icon
            data="@assetsIcon/brand/logo-icon.svg"
            scale="1"
            original
          />
        </router-link>
      </el-col>
      <el-col
        :xl="14"
        :lg="14"
        class="hidden-md-and-down"
      >
        <el-menu
          :default-active="activeMenuItem"
          class="navbar"
          mode="horizontal"
        >
          <el-menu-item index="feed-feed">
            <router-link :to="{ name: 'feed-feed', params: { isActiveFilter: 1 } }">
              <vsvg-icon
                data="@assetsIcon/nav-item/feed-icon.svg"
                scale="1"
                :color="brand.styles.primary"
              /><span>Feed</span>
            </router-link>
          </el-menu-item>
          <el-menu-item
            v-if="$store.getters.companyIsEnterprise"
            index="2"
          >
            <router-link :to="{ name: 'challenge-challenges' }">
              <vsvg-icon
                data="@assetsIcon/nav-item/challenge-icon.svg"
                scale="1"
                :color="brand.styles.primary"
              /><span>Challenges</span>
            </router-link>
          </el-menu-item>
          <el-menu-item
            v-if="$store.getters.companyIsEnterprise"
            index="3"
          >
            <router-link :to="{ name: 'leaderboard-leaderboard' }">
              <vsvg-icon
                data="@assetsIcon/nav-item/leaderboard-icon.svg"
                scale="1"
                :color="brand.styles.primary"
              /><span>Leaderboard</span>
            </router-link>
          </el-menu-item>
        </el-menu>
      </el-col>
      <el-col
        :xl="6"
        :lg="6"
        :md="21"
        :sm="21"
      >
        <div class="navbar-profile">
          <div
            class="bell-wrapper pointer"
            @click="$emit('on-notification-bell-clicked')"
          >
            <vsvg-icon
              data="@assetsIcon/bell-icon.svg"
              scale="1"
              original
            />
            <el-badge
              v-if="$store.state.unreadNots > 0"
              :value="$store.state.unreadNots > 99 ? '99+' : $store.state.unreadNots"
            />
          </div>

          <profile-dropdown />

          <div class="points-wrapper">
            <vsvg-icon
              data="@assetsIcon/star-icon.svg"
              scale="1"
              color="#ffffff"
              class="star"
            />
            <span
              class="points"
            >
              {{ $store.state.totalPoints }}
            </span>
          </div>
          <vsvg-icon
            data="@assetsIcon/collapse-icon.svg"
            scale="1"
            original
            class="collapse-icon hidden-lg-and-up pointer"
            @click="showMobileMenu = true"
          />
        </div>
      </el-col>
    </el-row>
    <transition name="slide">
      <mobile-navigation-sidebar
        v-if="showMobileMenu"
        v-on-click-outside="() => { showMobileMenu = false }"
        @on-close="showMobileMenu = false"
      />
    </transition>
  </div>
</template>

<script>
import ProfileDropdown from './profile-dropdown'
import { mixin as onClickOutside } from 'vue-on-click-outside'
import MobileNavigationSidebar from './mobile-navigation-sidebar'

export default {
  name: 'AppNavigationBar',

  components: {
    ProfileDropdown,
    MobileNavigationSidebar,
  },

  mixins: [
    onClickOutside
  ],

  data: () => ({
    activeMenuItem: 'feed-feed',
    showMobileMenu: false,
  }),
}
</script>

<style lang="scss">
.app-bar {
  .navbar-profile {
    display: flex;
    align-items: center;
    float: right;


    .bell-wrapper {
      position: relative;
      margin-right: 20px;
       .el-badge {
        margin-right: 0;
        bottom: -10px;
        right: 7px;
        .el-badge__content {
          border-radius: 15px;
          border-width: 2px;
          background-color: $status-red-color;
          transform: none;
        }
      }
    }

    .el-dropdown--profile {
      .avatar-arrow {
        position: relative;
        cursor: pointer;
        .el-icon-arrow-down {
          background-color: #FFFFFF;
          color: $primary-color;
          border-radius: 50%;
          box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.25);
          position: absolute;
          bottom: 0;
          font-weight: bold;
          padding: 2px;
          right: -9px;
        }
      }
      .avatar {
        width: 35px;
        height: 35px;
        border-radius: 50%;
      }
    }
    .points-wrapper {
      background: $primary-color;
      color: $white-color;
      border-radius: 15px;
      padding: 6px 10px;
      display: flex;
      justify-content: center;
      margin-left: 20px;
      .star {
        margin-right: 5px;
      }
      .points {
        font-size: 11px;
        line-height: 11px;
        font-weight: bold;
      }
    }
    .collapse-icon {
      margin-left: 20px;
    }
  }
}
</style>
