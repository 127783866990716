<template>
  <div class="header">
    <div class="container-fluid">
      <div class="container">
        <navigation-bar />
      </div>
    </div>
  </div>
</template>

<script>
import NavigationBar from './navigation-bar'

export default {
  name: 'PublicHeader',

  components: {
    'navigation-bar': NavigationBar,
  },
}
</script>
